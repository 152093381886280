import React from "react"
import { Link } from "gatsby"
import { MdConstruction } from "react-icons/md"
import SEO from "../components/seo"

const IndexContent = ({ isHidden }) => {
  if (isHidden) {
    return (
      <div className="container eggshell-text" style={{ textAlign: "center" }}>
        <h1>
          Under Construction <MdConstruction />
        </h1>
        <p>
          Connect with me on{" "}
          <a href="https://www.linkedin.com/in/nsmedira/">LinkedIn</a> or check
          back later.
        </p>
      </div>
    )
  }

  return (
    <div>
      <div className="container">
        <div className="section" style={{ marginTop: "2em" }}>
          <div className="row">
            <Link to={"/portfolio"}>
              <div className="col s4">
                <img
                  src="https://res.cloudinary.com/nsmedira/image/upload/f_auto,q_auto/e_replace_color:ece2d0/v1597433173/projects_zxehfv.png"
                  alt="portfolio"
                  className="responsive-img circle hoverable _tooltipped"
                  data-position="bottom"
                  data-tooltip="View my portfolio..."
                />
                <p className="flow-text eggshell-text center-align">
                  Portfolio
                </p>
              </div>
            </Link>
            <Link to={"/skills"}>
              <div className="col s4">
                <img
                  src="https://res.cloudinary.com/nsmedira/image/upload/f_auto,q_auto/e_replace_color:ece2d0/v1597433173/skills_n1erkl.png"
                  alt="skills"
                  className="responsive-img circle hoverable _tooltipped"
                  data-position="bottom"
                  data-tooltip="View my skills..."
                />
                <p className="flow-text eggshell-text center-align">Skills</p>
              </div>
            </Link>
            <Link to={"/blog"}>
              <div className="col s4">
                <img
                  src="https://res.cloudinary.com/nsmedira/image/upload/f_auto,q_auto/e_replace_color:ece2d0/v1597433173/blog_qqvelt.png"
                  alt="skills"
                  className="responsive-img circle hoverable _tooltipped"
                  data-position="bottom"
                  data-tooltip="View my blog..."
                />
                <p className="flow-text eggshell-text center-align">Blog</p>
              </div>
            </Link>
          </div>
        </div>

        <div className="divider"></div>

        <div className="row">
          <div className="col s12 m6">
            <div className="section">
              {/* <div className="featured-post eggshell-text"> */}
              <header>
                <div className="post-meta">
                  <span className="featured-badge">Latest Tweet</span>
                </div>
              </header>
              <a
                className="twitter-timeline"
                data-theme="dark"
                data-tweet-limit="1"
                href="https://twitter.com/nsmedira?ref_src=twsrc%5Etfw"
              >
                Latest Tweet by nsmedira
              </a>
              <script
                async
                src="https://platform.twitter.com/widgets.js"
                charSet="utf-8"
              ></script>
              {/* </div> */}
            </div>
          </div>
          <div className="col s12 m6">
            <div className="section">
              <div className="featured-post eggshell-text">
                <article>
                  <header>
                    <div className="post-meta">
                      <span className="featured-badge">Featured Post</span>
                      &nbsp;/&nbsp;
                      <time
                        className="published eggshell-text"
                        dateTime="2020-10-10"
                      >
                        October 10, 2020
                      </time>
                    </div>
                    <h2 className="home">
                      What I learned from porting a Create React App site to
                      Gatsby
                    </h2>
                  </header>
                  <div className="post-content">
                    <p className="flow-text home">
                      I had just finished creating my first Create React App, a
                      shiny new portfolio site that I had proudly deployed to
                      Firebase Hosting and shared on Twitter. Everything was
                      working well. There was just one problem: despite using
                      React-Helmet to set the appropriate meta tags for blog
                      posts, the Twitter Card Validator couldn't see them...
                    </p>
                    <div className="chip eggshell blue-text hoverable">
                      gatsby
                    </div>
                    <div className="chip eggshell blue-text hoverable">
                      react
                    </div>
                    <div className="read-more">
                      <Link
                        to="/blog/porting-create-react-app-to-gatsby-what-i-learned"
                        className="waves-effect waves-light btn hoverable"
                      >
                        Continue Reading
                      </Link>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>

        <div className="divider last-divider"></div>
      </div>
    </div>
  )
}

const IndexPage = () => (
  <>
    <SEO title="Home" path="/" />
    <IndexContent isHidden />
  </>
)

export default IndexPage
